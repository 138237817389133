import React, { useState, useEffect, useRef } from "react";
import Row from "./Row";
import Api from "./useApiClient";
import moment from "moment";

const LEADERBOARD_REFRESH_INTERVAL_MS = 120 * 1000;

const Leaderboard = () => {
  const api = new Api();
  const [users, setUsers] = useState([]);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get("competition");
  const products = useRef([]);
  const categories = useRef([]);
  const competitionName = useRef("");
  const startDate = useRef();
  const endDate = useRef();
  let currentDateAndTime = useRef(moment().format("DD MMMM HH:mm"));

  useEffect(() => {
    let timerId = setTimeout(function fetchData() {
      let timeRequested = new Date();
      try {
        api.getCompetitionDetails(id).then((res) => {
          const data = res.data;
          competitionName.current = data.name;
          categories.current = data.categories;
          products.current = data.products;
          setUsers(data.leaderboard);
          startDate.current = data.starts;
          endDate.current = data.ends;
        });
      } finally {
        let timeResponsed = new Date();
        let timeElapsed = timeResponsed - timeRequested;
        let newRefreshInterval = LEADERBOARD_REFRESH_INTERVAL_MS - timeElapsed;
        timerId = setTimeout(
          fetchData,
          newRefreshInterval > 0
            ? newRefreshInterval
            : LEADERBOARD_REFRESH_INTERVAL_MS
        );
      }
    }, 0);
  }, []);

  return (
    <div id="main-layout">
      <div className="left">
        <h2 className="fw-500">The competitions</h2>
        <div className="text-light-1 fw-600 fs-22">
          {moment(startDate.current).format("DD MMMM HH:mm")}
        </div>
        <div className="text-light-1 fw-600 fs-22">
          {moment(endDate.current).format("DD MMMM HH:mm")}
        </div>

        <div className="items">
          {categories.current.map((el, idx) => {
            return (
              <div key={"left-item" + idx} className="item">
                <h3 className="lh-36">{el.name}</h3>
                <h3 className="text-light-2 text-light-2 d-flex align-items-center mt-10">
                  <div className="star-wrap me-3">
                    <img src="/assets/images/star.svg" alt="star" />
                  </div>
                  {el.points} points
                </h3>
              </div>
            );
          })}
          {products.current?.map((el, idx) => {
            return (
              <div key={"left-item" + idx} className="item">
                <h3 className="lh-36">{el.name}</h3>
                <h3 className="text-light-2 text-light-2 d-flex align-items-center mt-10">
                  <div className="star-wrap me-3">
                    <img src="/assets/images/star.svg" alt="star" />
                  </div>
                  {el.points} points
                </h3>
              </div>
            );
          })}
        </div>
      </div>
      <div className="right">
        <div className="title d-flex justify-content-between ">
          <h1 className="fw-700">🏆 {competitionName.current}</h1>
          <h1 className="fw-normal">{currentDateAndTime.current}</h1>
        </div>

        <div className="cards">
          <div className="container-fluid">
            <div className="row">
              {users
                .sort((a, b) => b.points - a.points)
                .map((el, idx) => {
                  return (
                    <div key={idx}>
                      <Row className="col-lg-6" idx={idx} el={el} />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Leaderboard;
