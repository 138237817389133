import React from "react";
import clsx from "clsx";

const Row = ({ idx, el }) => {
  const place = idx + 1;

  return (
    <div
      className={clsx(
        "card",
        place === 1 && "first",
        place === 2 && "second",
        place === 3 && "third"
      )}
    >
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <div className="number pe-3 text-center">
            <h3 className="fw-700">
              {place < 10 ? "0" : ""}
              {place}
            </h3>
          </div>

          <h4 className="ps-3 fw-700">
            {el.firstName} {el.lastName}
          </h4>
        </div>
        <div className="d-flex align-items-center">
          <h4 className="stars-num fw-700">{el.points}</h4>
          <div className="star-wrap ms-3">
            {idx === 0 ? (
              <img src="/assets/images/bronze-star.svg" alt="star" />
            ) : idx === 1 ? (
              <img src="/assets/images/gold-star.svg" alt="star" />
            ) : idx === 2 ? (
              <img src="/assets/images/silver-star.svg" alt="star" />
            ) : (
              <img src="/assets/images/star.svg" alt="star" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Row;
