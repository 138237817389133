import Leaderboard from "./Leadeboard";

// CSS
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/css/bootstrap-grid.min.css";
import "./styles/styles.css";

function App() {
  return <Leaderboard />;
}

export default App;
