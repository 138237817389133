import axios from "axios";
import axiosRetry from "axios-retry";

export default class Api {
  constructor() {
    this.api_token = null;
    this.api_url = process.env.REACT_APP_API_URL;
    this.client = this.init();
  }
  init = () => {
    let headers = {
      Accept: "application/json",
    };
    this.api_token = this.getAccessToken();
    if (this.api_token) {
      headers.Authorization = `Bearer ${this.api_token}`;
    }
    this.client = axios.create({
      baseURL: this.api_url,
      timeout: 61000,
      headers: headers,
      params: {
        limit: 86450100,
      },
    });
    axiosRetry(this.client, { retries: 3, shouldResetTimeout: true });
    return this.client;
  };

  getCompetitionDetails = (competitionId) => {
    return this.client
      .get(`competitions/${competitionId}/leaderboard`)
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  getAccessToken = () => {
    return (
      document.cookie.match("(^|;)\\s*access_token\\s*=\\s*([^;]+)")?.pop() || ""
    );
  };
}
